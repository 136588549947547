﻿.readonly{
  background-color: lightgray;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text { 	color: transparent; }

.on-error{
  color:red;
  font-style: italic;
}

input.ng-invalid.ng-touched,
form.ng-touched>input.ng-invalid,
input.invalid{
  border:solid red 1px;
}

.readonly-fields,input.readonly-fields:focus {
  background-color: #E3E3E3;
  color:#AAAAAA;
}

