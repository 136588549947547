﻿.tooltip-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.tooltip-content {
  max-width:25rem;
  width:100%;
z-index: 99999
}

.tooltip-img {
  width: 100%;
}

@media screen and (max-width: 576px){

  .tooltip-content {
    max-width: 20rem;
  }
}
