﻿.elec-card{
  background-color: $elec;
}
.gas-card{
  background-color: $gas;
}
.card-img-top{
  width:80%;
  border-radius: 0;
}
.card{
  box-sizing: border-box;
  .card-text{
    width:100%
  }
  .form-check{
    padding:0;
    margin:0;
  }
}
