/* You can add global styles to this file, and also import other style files */
@import "style/abstracts/_abstracts-dir";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "style/base/_base-dir";
@import "style/components/_components-dir";

html, body {
  font-family: "Montserrat", sans-serif;
}
body {
  background: #f7f7f7;
}

.container-wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}
