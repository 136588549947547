
@import "../../style/abstracts/variables";

.btn {
    transition: background-color 0.5s ease;
    font-size: 18px;
}

.btn-actions {
    gap: 10px;
    display: flex;
    justify-content: center;
}

.btn.bg-primary {
    font-size: 18px;
    &:hover {
        background-color: $primary-dark !important;
    }
}

.btn-white {
  display:inline-flex;
  align-items: center;
    background: white;
    color: $primary;
    border-radius: 50px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 30px;
    transition: background-color 0.5s ease;

    &.btn-phone {
        background: none;
        color: white;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        & > svg { width: 24px;}

        //&:hover {
        //    background: white;
        //    color: $primary;
        //    #ico-phone { fill: $primary;}
        //}
    }
}

.btn-white:hover {
  border: solid white 1px;
}

@media screen and (max-width: 575px) {
    .btn-actions {
        flex-direction: column;
        align-items: center;
        & > .btn { width: 80%;}
    }
}



